import React from "react";
import { useTranslation } from "react-i18next";
import { main } from './commercial-moving.module.css';
import { internalContainer } from '../../styles/utils.module.css';
import Layout from '../../components/Layout';
import InternalSection from '../../components/InternalSection';
import InternalSectionTitle from '../../components/InternalSectionTitle';
import InternalBottom from '../../components/InternalBottom';
import Service from "../../components/Service";
import useImages from '../../hooks/useImages';
import { findImageData } from '../../utils/tools';

export default () => {
    const { t } = useTranslation();

    const images = useImages();

    const copy = t('services.commercialMoving', { returnObjects: true });

    const { callToAction, ...widget } = t('home.tabbedNavigation.1', { returnObjects: true });

    return (
        <Layout>
            <InternalSection content={copy}>
                <div className={`${main} ${internalContainer}`}>
                    <div>
                        <InternalSectionTitle>{copy.mainContent.title}</InternalSectionTitle>
                        {copy.mainContent.paragraphs.map((p, index) => (
                            <p key={index}>{p}</p>
                        ))}
                        <Service data={{ ...widget, imageData: findImageData(images, widget.image) }} />
                    </div>
                    <InternalBottom service content={t('services.common', { returnObjects: true })} />
                </div>
            </InternalSection>
        </Layout>
    );
}
